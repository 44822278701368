// const url = 'http://localhost:3003/';
const url = 'https://api.elma.online/';

const s3SubFolder = '';

const config = {
  url,
  port: 3000,
  s3SubFolder,
  dlUrl: `${url}dl/`,
  api: `${url}api/`,
  up: `https://api.elma.online/u/`,
  maps: 'AIzaSyDE8Prt4OybzNNxo1MzIn1XYNGxm9rI8Zk',
  recaptcha: '6Ld33tQUAAAAAN-l3f_UM--3_aAI8oMjLHSSJdxb',
  maxUploadSize: 10485760,
  s3Url: `https://space.elma.online/${s3SubFolder}`,
  hotJarId: 4987601,
};

export default config;
